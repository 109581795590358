import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';

type Props = {
  id: string | number;
  label?: string;
  value?: string;
  onClick?(): void;
  onCheckboxChange: SwitchBaseProps['onChange'];
  checked?: boolean;
  rightIcon?: React.ReactElement;
};

const ListItem = React.forwardRef<HTMLButtonElement, any>(
  (
    {
      label,
      value,
      onClick,
      checked,
      rightIcon = null,
      onCheckboxChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <ListItemButton
        {...rest}
        ref={ref}
        key={value}
        role="listitem"
        onClick={onClick}
      >
        <ListItemIcon>
          <Checkbox
            checked={checked}
            onChange={onCheckboxChange}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={label} />
        {rightIcon}
      </ListItemButton>
    );
  },
);

export default React.memo(ListItem);
